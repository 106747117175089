@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
}

.m-t-20 {
  margin-top: 20px
}

.hide {
  display: none
}

.notes::placeholder {
  color: #A4B0CC;
  opacity: 1;
}
.table_bg .ant-table-thead > tr > th{
  background-color: #E5E8F0;
  font-weight:600;
  color: #4D6189;
}
tr:nth-child(odd) {
  background-color: #FFFFFF;
}
th, td {
  color: #4D6189;
  font-size: 14px;
}

@font-face {
  font-family: "Figtree-Bold";
  src: url('./assets/fonts/Figtree-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: "Figtree-Medium";
  src: url('./assets/fonts/Figtree-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: "Figtree-SemiBold";
  src: url('./assets/fonts/Figtree-SemiBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: "Figtree-ExtraBold";
  src: url('./assets/fonts/Figtree-ExtraBold.woff2') format('woff2');
  font-display: swap;
}
