input:focus { 
    background-color: #ffffff;
  }
  
  label {
    font-weight: 500
  }
  
  .ant-select-selection {
    background: #f1f2f6; 
  }
  
  .ant-select-dropdown-menu-item {
    padding: 10px 18px;
    font-size: 16px;
    font-weight: normal;
    color: #555555;
  }
  
  .ant-dropdown-menu-item {
    padding: 10px 18px;
    font-size: 16px;
    font-weight: normal;
    color: #555555;
  }
  
  .ant-tooltip-inner {
    padding: 11px 17px;
  }
  .ant-btn .anticon { 
    margin: 0px !important;
  }
  .ant-btn > .anticon + span, .ant-btn > span + .anticon {
    margin-left: 10px
  }
  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 40px;
  }
  .ant-pagination {
    color: #fff
  }
  .ant-pagination-item {
    border-radius: 50%;
    border: 0px solid #fff;
    text-align: center;
    padding-top: 2px;
  }
  .ant-pagination-item:hover {
    border: 0px solid #fff;
  }
  .ant-pagination-item:hover a {
    color: #757575;
    font-weight: 500
  }
  .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    border-radius: 50%;
    text-align: center;
    border: 1px solid #d2d2d2;
    background-color: #fff;
  }
  .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    border: 1px solid #757575;
  }
  .ant-pagination-item-active {
    border: 0px solid #fff;
    background-color: #e8e8e8;
  }
  .ant-pagination-item-active a {
    color: #757575;
  }
  .ant-calendar {
    font-size: 13px
  }
  .ant-calendar-date {
    padding-top: 1px;
    padding-left: 1px;
   }
  .ant-calendar-date:hover {
    border-radius: 50%
  }
  .ant-calendar-selected-day .ant-calendar-date {
    border-radius: 50%;
    font-weight: normal
  }
  .ant-calendar-today .ant-calendar-date {
    border-radius: 50%;
    font-weight: normal;
    background-color: #e8e8e8;
    border: 0px;
    color: #757575;
  }
  .ant-calendar-date-input-wrap input {
    background-color: #fff !important
  }
  .ant-radio-button-wrapper-checked {
    background: #f1f2f6;
    border-color: #8c9197;
    color: #333333;
    box-shadow: -1px 0 0 0 #8c9197;
  }  
  .ant-radio-button-wrapper-checked:first-child {
    border-color: #8c9197;
  }
  